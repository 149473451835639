const courses = [
    {
        vidTitle: "Dr. Sharlene Gill: Frank's Story",
        vidSubtitle: "Optimizing Treatment Outcomes in mCRC Case Report.",
        tabs: [
            {
                head: "1. Disease and Case Overview",
                video: "449855588"
            },
            {
                head: "2. First Line Treatment",
                video: "449855568"
            },
            {
                head: "3. Second Line Treatment",
                video: "449855535"
            },
            {
                head: "4. Third Line Treatment",
                video: "449855537"
            },
            {
                head: "5. Update and Case Summery",
                video: "449855529"
            },
            {
                head: "6. Watch Frank's Story",
                video: "449855672"
            },
            {
                head: "Module Complete",
            },
        ],
        presentation: "Watch Dr. Gill's Full Case Presentation",
        presentationVid: "449855688",
        story: "Watch Frank's Story",
        storyVid: "449855672"
    },
    {
        vidTitle: "Dr. Petr Kavan: Line's Story",
        vidSubtitle: "Optimizing Treatment Outcomes in mCRC Case Report.",
        tabs: [
            {
                head: "1. Disease and Case Overview",
                video: "456993445"
            },
            {
                head: "2. First Line Treatment",
                video: "456999158"
            },
            {
                head: "3. Second Line Treatment",
                video: "457002344"
            },
            {
                head: "4. Third Line Treatment",
                video: "457006193"
            },
            {
                head: "5. Treatment Considerations and Updates",
                video: "457010191"
            },
            {
                head: "6. Watch Line's Story",
                video: "467727785"
            },
            {
                head: "Module Complete",
            },
        ],
        presentation: "Watch Dr. Kavan's Full Case Presentation",
        presentationVid: "456797515",
        story: "Watch Line's Story",
        storyVid: "467727785"
    },
    {
        vidTitle: "Dr. Shaqil Kassam: Joyce’s Story",
        vidSubtitle: "Optimizing Treatment Outcomes in mGC: Case Report",
        tabs: [
            {
                head: "1. Disease Overview",
                video: "528534820"
            },
            {
                head: "2. Case Overview",
                video: "528534622"
            },
            {
                head: "3. First Line Treatment",
                video: "528534291"
            },
            {
                head: "4. First Line Outcomes",
                video: "528534170"
            },
            {
                head: "5. Second Line Treatment",
                video: "528534536"
            },
            {
                head: "6. Second Line Outcomes",
                video: "528905933"
            },
            {
                head: "7. Case Summary",
                video: "528534743"
            },
            {
                head: "Module Complete",
            },
        ],
        presentation: "Watch Dr. Kassam's Full Case Presentation",
        presentationVid: "528919806",
        story: "Watch Joyce’s Story",
        storyVid: "530156394"
    }
]

const cases = [
    {
        case: "Onco-1",
        dr: "Dr. Sharlene Gill: Frank’s Story",
        report: "Optimizing Treatment Outcomes in mCRC: Case Report",
    },
    {
        case: "Onco-2",
        dr: "Dr. Petr Kavan: Line’s Story",
        report: "Optimizing Treatment Outcomes in mCRC: Case Report",
    },
    {
        case: "Onco-3",
        dr: "Dr. Shaqil Kassam: Joyce’s Story",
        report: "Optimizing Treatment Outcomes in mGC: Case Report",
    },
];

const prov = [
    { name: "Alberta", value: "alberta" },
    { name: "British Columbia", value: "britishColumbia" },
    { name: "Manitoba", value: "manitoba" },
    { name: "New Brunswick", value: "newBrunswick" },
    { name: "Newfoundland and Labrador", value: "newfoundlAndLabrador" },
    { name: "Northwest Territories", value: "northwestTerritories" },
    { name: "Nova Scotia", value: "novaScotia" },
    { name: "Nunavut", value: "Nunavut" },
    { name: "Ontario", value: "Ontario" },
    { name: "Prince Edward Island", value: "princeEdwardIsland" },
    { name: "Quebec", value: "Quebec" },
    { name: "Saskatchewan", value: "Saskatchewan" },
    { name: "Yukon", value: "Yukon" },
];

const pract = [
    { name: "Doctor", value: "doctor" },
    { name: "Pharmacist", value: "pharmacist" },
    { name: "Nurse", value: "nurse" },
];

const licMask = {
    doctor: {
        alberta: [6],
        britishColumbia: [5],
        manitoba: [5],
        newBrunswick: [5, 7],
        newfoundlAndLabrador: [5],
        novaScotia: [6],
        Ontario: [5, 6],
        princeEdwardIsland: [3, 4],
        Quebec: [5],
        Saskatchewan: [6],
    },
    pharmacist: {
        alberta: [4, 5],
        britishColumbia: [5],
        manitoba: [5],
        newBrunswick: [4],
        newfoundlAndLabrador: [5, 6],
        northwestTerritories: [5, 9],
        novaScotia: [3, 4, 5],
        Ontario: [6],
        Quebec: [6],
        Saskatchewan: [4, 5],
        Yukon: [9, 8],
    },
    nurse: {
        alberta: [5, 6],
        britishColumbia: [5, 7],
        manitoba: [6],
        newBrunswick: [6],
        newfoundlAndLabrador: [5],
        northwestTerritories: [4, 5, 9],
        novaScotia: [5, 6],
        Nunavut: [3, 4],
        Ontario: [7, 8],
        princeEdwardIsland: [6],
        Quebec: [6, 7],
        Saskatchewan: [7],
    },
}

export default courses
export { prov, pract, licMask, cases }