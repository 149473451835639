// import firebase from "firebase/app"
// import "firebase/auth"
// import "firebase/firestore"
// import "firebase/storage"
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {getAuth} from "firebase/auth"
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAkAGHBQYpEJ4f8zvfQRUQk5lkKmyksBbs",
    authDomain: "minds-c85e5.firebaseapp.com",
    projectId: "minds-c85e5",
    storageBucket: "minds-c85e5.appspot.com",
    messagingSenderId: "537902541731",
    appId: "1:537902541731:web:1be48b4dc8a4ea39a90816",
    measurementId: "G-KRE7DN3PX3"
  };
  
// firebase.initializeApp(firebaseConfig)
// const auth = firebase.auth()
// const db = firebase.firestore()
// const storage = firebase.storage()
// const timestamp = firebase.firestore.FieldValue.serverTimestamp
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)

export { auth, db, storage }
