import { ref } from "vue";
import { auth } from "../firebase/config";
import { signOut } from "firebase/auth";

const useLogout = async () => {
  const logoutError = ref(null);
  try {
    await signOut(auth);
  } catch (err) {
    console.log(err);
    logoutError.value = err.message;
  }
  return { logoutError };
};
export default useLogout;
