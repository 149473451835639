import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '../firebase/config'
import Home from '../views/Home.vue'

const mainGuard = (to, from, next) => {
    const user = auth.currentUser
    if (user) {
        next()
    } else {
        next({ name: "Home" })
    }
}
const signingGuard = (to, from, next) => {
    const user = auth.currentUser
    if (!user) {
        next()
    } else {
        next({ name: "main" })
    }
}

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        beforeEnter: signingGuard
    },
    {
        path: '/main',
        name: 'main',
        component: () =>
            import ("../views/main.vue"),
        beforeEnter: mainGuard
    },
    {
        path: '/case-report/:number',
        name: 'caseReport',
        component: () =>
            import ("../views/caseReport.vue"),
        beforeEnter: mainGuard,
        props: true
    },
    {
        path: '/profile/:id',
        name: 'myProfile',
        component: () =>
            import ("../views/myProfile.vue"),
        beforeEnter: mainGuard,
        props: true
    },
    {
        path: '/resetPassword',
        name: 'resetPassword',
        component: () =>
            import ('../views/resetPassword.vue'),
        props: true
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router